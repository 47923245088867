import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'

const Template = ({ data, location }) => {
    const category = data.taxonomyTermCategory
    return (
      <Layout location={location}>
        <section className="container mx-auto px-5">
          <div className="page-title">{category.name}</div>
        </section>
      </Layout>
    )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
    query($categoryId: String!) {
        taxonomyTermCategory(id: { eq: $categoryId }) {
          id
          name
          path {
            alias
          }
        }
    }`